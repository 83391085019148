import React from 'react'
// import { Link, graphql } from "gatsby"

import Layout from '../components/Layout'
// import Image from "../components/image"
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Jimmy Kimmel Live! ALS Charity Drive" />
  </Layout>
)

export default IndexPage
